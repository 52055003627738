import axiosInstance from "../lib/axiosInstance.js";

class UserBetService {
    #url = "/ab/rest/cpanel/userbets"

    getUserBetsList(userId, page, selectedBlogId) {
        var data = {
            page: page
        };

        if (null != selectedBlogId) {
            data['blogId'] = selectedBlogId;
        }

        return axiosInstance.get(this.#url + "/user/"+userId, {
            params: data
        })
    }

    getOrderBetsByOrder(orderBetId, page) {
        return axiosInstance.get(this.#url + "/orderbet/"+orderBetId, {
            params: {
                page: page
            }
        })
    }

    getUserBlogs(userId) {
        return axiosInstance.get(this.#url + "/user/blogs/" +userId)
    }

    getUserBetSystemProfit(selectedYear) {
        return axiosInstance.get(this.#url + "/system/profit", {
            params: {
                year: selectedYear
            }
        })
    }

}

export default new UserBetService()