<template>
    <div class="row">
        <div class="col-md-3">
            <SystemProfit></SystemProfit>
        </div>
    </div>
</template>

<script>
    import SystemProfit from "../components/SystemProfit";

    export default {
        name: "IncomeProfit",
        components: {SystemProfit}
    }
</script>